jQuery(document).ready(function($){

    $('.quantity__minus').click(function(event) {
        event.preventDefault();

        var sel = $(this).data('quantity');
        var $qty_el = $('#' + sel);

        if ($qty_el.length) {
            var cur_qty = parseInt($qty_el.val());
            var new_qty = Math.max(1, cur_qty - 1);
            $qty_el.val(new_qty);
            $qty_el.trigger('change');
        }
    });

    $('.quantity__plus').click(function(event) {
        event.preventDefault();

        var sel = $(this).data('quantity');
        var $qty_el = $('#' + sel);

        if ($qty_el.length) {
            var cur_qty = parseInt($qty_el.val());
            var new_qty = cur_qty + 1;
            var max_qty = $qty_el.attr('max');

            if (max_qty) {
                new_qty = Math.min(new_qty, max_qty);
            }

            $qty_el.val(new_qty);
            $qty_el.trigger('change');
        }
    });

    $('form.checkout').on('click', '.wc_payment_method .input-radio', function() {
        $('form.checkout .wc_payment_method').each(function () {
            var has_checked = $(this).find(".input-radio:checked").length;

            if (has_checked) {
                $(this).addClass("active");
            } else {
                $(this).removeClass("active");
            }
        });
    });

    $('body').on('wc_cart_button_updated', function(event, $button) {
        $button.text(tense_vars.added_to_cart_caption);
    });

    $('body').on('added_to_cart', function(event) {
        $('.woocommerce-notice-wrap').html('<p class="woocommerce-info">' + tense_vars.added_to_cart_notice + '</p>');

        $('html, body').animate({
            scrollTop: $('.woocommerce-notice-wrap').offset().top
        }, 800);
        return false;
    });

    $(document).on( 'found_variation', function( event, variation ) {
        if (variation.image_id == undefined || !variation.image_id) {
            return;
        }

        var sliders = ['.product__slider--main', '.product__slider--nav'];


        sliders.forEach(function(selector) {
            var slide_selector = selector + ' .slide[data-image-id="' + variation.image_id + '"]';
            var $slides = $(slide_selector);

            if ($slides.length) {
                var slide_index = $slides.eq(0).data('slick-index');
                $(selector).slick('slickGoTo', parseInt(slide_index));

                // workaround for slick bug
                $(selector).find('.slide').removeClass("slick-current");
                slide_selector = selector + ' .slide[data-slick-index="' + slide_index + '"]';
                $(slide_selector).addClass("slick-current");
            } else {
                var ajax_data = {
                    action: 'get_variation_images_html',
                    variation_id: variation.variation_id,
                };

                if (selector.endsWith('--nav')) {
                    ajax_data['template_type'] = 'thumb';
                }

                $.post(
                    tense_vars.ajax_url,
                    ajax_data,
                    function (response) {

                        var slider_opts = {
                            '.product__slider--main': {
                                dots: false,
                                speed: 1000,
                                infinite: true,
                                autoplay: false,
                                cssEase: 'ease-out',
                                arrows: false,
                                fade: true,
                                responsive: [
                                {
                                    breakpoint: 640,
                                    settings: "unslick",
                                },
                                ],
                            },
                            '.product__slider--nav': {
                                dots: false,
                                speed: 1000,
                                infinite: true,
                                autoplay: false,
                                cssEase: 'ease-out',
                                arrows: false,
                                fade: false,
                                vertical: true,
                                slidesToShow: 3,
                                slidesToScroll: 1,
                                centerMode: false,
                                focusOnSelect: true,
                                asNavFor: '.product__slider--main',
                                responsive: [
                                    {
                                        breakpoint: 1280,
                                        settings: {
                                            vertical: false,
                                        }
                                    },
                                    {
                                        breakpoint: 1024,
                                        settings: {
                                            slidesToShow: 2,
                                            vertical: false,
                                        }
                                    },
                                    {
                                        breakpoint: 640,
                                        settings: {
                                            slidesToShow: 1,
                                            vertical: false,
                                        }
                                    }
                                ],
                            },
                        }
                        var options = slider_opts[selector];
                        $(selector).slick("unslick").html(response.data).slick(options);

                        jQuery(selector + ' a[rel="fancybox"]').fancybox({
                            'cyclic': false,
                            'autoScale': true,
                            'padding': 10,
                            'opacity': true,
                            'speedIn': 500,
                            'speedOut': 500,
                            'changeSpeed': 300,
                            'overlayShow': true,
                            'overlayOpacity': "0.3",
                            'overlayColor': "#666666",
                            'titleShow': true,
                            'titlePosition': 'inside',
                            'enableEscapeButton': true,
                            'showCloseButton': true,
                            'showNavArrows': true,
                            'hideOnOverlayClick': true,
                            'hideOnContentClick': false,
                            'width': 560,
                            'height': 340,
                            'transitionIn': "fade",
                            'transitionOut': "fade",
                            'centerOnScroll': true
                        });
                    }
                );
            }
        });
    });

    $( document.body ).on( 'updated_cart_totals', function(){
        if ( $( '.woocommerce-gifts' ).length ) {
            $( '.woocommerce-gifts__item' ).each(function( ) {
                $( '.dgfw-add-gift-button, .dgfw-select-gift-button' ).prop( 'disabled', false);
            });
        }
    });


    $( window ).on( "load", function() {
        if ( $( '.woocommerce-gifts' ).length ) {
            $( '.woocommerce-gifts__item' ).each(function( ) {
                $( '.dgfw-add-gift-button, .dgfw-select-gift-button' ).prop( 'disabled', false);
            });
        }
    });

    function tense_maybe_set_limit(sel) {
        $(sel).each(function() {
            var limit = parseInt($(this).attr('maxlength'));

            if (limit && limit != -1) {
                $(this).EnsureMaxLength({
                    limit: parseInt(limit),
                });
            }
        });
    }

    tense_maybe_set_limit('.form-control[data-type="text"]');

    $(document).on( 'found_variation', function( event, variation ) {
        $('[data-availability]').html(variation.availability_html);
    });

    $('body').on('DOMSubtreeModified', '.shape-square', function() {
        var width = $(this).width();
        $(this).height(width);
    });

    if (navigator.userAgent.indexOf("MSIE ") > 0 || navigator.userAgent.indexOf("Trident") > 0) {
        $('.browser-warning').text('Używasz przestarzałej przeglądarki Internet Explorer przez co sklep może nie działać prawidłowo. Użyj innej, aktualnej przeglądarki, np. Firefox lub Chrome.').show();
    }

});

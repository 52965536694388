jQuery(document).ready(function($){

    function tense_equalize_slides($slides) {
        if (Foundation.MediaQuery.atLeast('medium')) {
            var max_height = 0;
            $slides.each(function() {
                max_height = Math.max($(this).height(), max_height);
            });
            $slides.height(max_height);
        } else {
            $slides.css('height', 'auto');
        }
    }

    $('[data-slider-equalize]').on('init reInit', function(event, slick) {
        tense_equalize_slides(slick.$slides);
    });

    function tense_resize_handler() {
        $tense_sliders.each(function() {
            var slick = $(this).slick('getSlick');
            tense_equalize_slides(slick.$slides);
        });
    }

    var $tense_sliders = $('[data-slider-equalize]');

    if ($tense_sliders.length) {
        var resize_timer;

        $(window).resize(function() {
            clearTimeout(resize_timer);

            resize_timer = setTimeout(tense_resize_handler, 200);
        });
    }

    var home_slider_num = $('.home-slider__item').length;

    var caption = zeroPad(home_slider_num, 2);

    $('.home-slider__counter__total').text(caption);

    $('.home-slider').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        appendArrows: '.home-slider__arrows',
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: true,
    });

    var home_product_opts = {
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    var home_product_opts_alt = {
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
        fade: true,
        adaptiveHeight: true,
    };

    $('.home-product-slider').slick(home_product_opts);

    $('.home-product-slider--alt').slick(home_product_opts_alt);

    $('.upsells .products').slick(home_product_opts);

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left"></i></button>',
                    nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right"></i></button>',
                }
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        vertical: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ],
    });

    $('.home1__tabs').on('change.zf.tabs', function() {
        var $slider = $('.home1__tabs-panel.is-active .home-product-slider');
        $slider.slick('unslick');
        $slider.slick(home_product_opts);
        Foundation.reInit('equalizer');
    });

    $('.home2__tabs').on('change.zf.tabs', function() {
        var $slider = $('.home2__tabs-panel.is-active .home-product-slider');
        $slider.slick('unslick');
        $slider.slick(home_product_opts);
        Foundation.reInit('equalizer');
    });

    $('.home4__tabs a[data-slide]').click(function(event) {
        event.preventDefault();
        var slide_num = $(this).data('slide');
        $('.home4 .home-product-slider--alt').slick('slickGoTo', slide_num);
    });

    $('.home4 .home-product-slider--alt').on('beforeChange', function(event, slick, cur_slide, next_slide) {
        $('.home4__tabs-title.is-active').removeClass('is-active');
        var $el = $('.home4__tabs a[data-slide="' + next_slide + '"]');
        $el.parents('.home4__tabs-title').addClass('is-active');
    });

    function zeroPad(num, places) {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    }

    $('.home-slider').on('beforeChange', function(event, slick, cur_slide, next_slide) {
        var caption = zeroPad(next_slide + 1, 2);
        $('.home-slider__counter__current').text(caption);
    });

});

jQuery(document).ready(function($){

    $('.is-accordion-submenu-parent > a').click(function(){
        if ($(this).attr('href') != '#') {
            $(this).unbind('click');
        }
    });

    $('[data-search]').click(function(event) {
        event.preventDefault();
        $('#header__search').slideToggle();
    });

});
